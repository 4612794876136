import { template as template_1465cf5cb479489cb6c4d1d17b0bb488 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1465cf5cb479489cb6c4d1d17b0bb488(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
