import { template as template_35058595445c43eb88b24c4f0d019c66 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_35058595445c43eb88b24c4f0d019c66(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
