import { template as template_6b91dfd9eb844eb284810c31078819d2 } from "@ember/template-compiler";
const FKText = template_6b91dfd9eb844eb284810c31078819d2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
