import { template as template_38b98d8c6b34498397aea08434058f95 } from "@ember/template-compiler";
const SidebarSectionMessage = template_38b98d8c6b34498397aea08434058f95(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
